import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContent from '../components/ServiceDetails/ServiceDetailsContent'
import RelatedServices from '../components/ServiceDetails/RelatedServices'
 
const Details = () => {

    const technologies = [
        {
            id: 0,
            className: 'python',
            technology: 'Python',
        },
        {
            id: 1,
            className: 'java',
            technology: 'Java',
        },
        {
            id: 2,
            className: 'c_sharp',
            technology: 'C# (C Sharp)',
        },
    ];

    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Service Details Unified APIs" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Service Details " 
            />
            
            <ServiceDetailsContent 
                technologies={technologies} 
                subtitle={"API integration & Data Syncing"}
                description={"We offer solutions for connecting existing systems to a unified layer. Our team have a defined process for connecting backend systems or existing third party APIs to a unified API layer using custom technologies."}
                nextsteps={"To get started contact us today to set up a consulting session. Unified API layers are fast becoming the backbone behind digital economies, this is due to the added simplicity they bring for platform tasks and operations. Wether you are looking for a unified solution for your edge network or simply need a logistics interface, we have you covered!"}
                email={"info@theparse.co.za"}
            />
            {/* <RelatedServices /> */}
            <Footer />
        </Layout>
    );
}

export default Details